export const zh = {
  了解天窗灯: "了解天窗灯",
  自然的光: "自然的光",
  健康的光: "健康的光",
  全新的应用场景: "全新的应用场景",
  领英: "领英",
  lang: 'English',
  image: '图片',
  video: '视频',
  name: '深圳市一窗科技有限责任公司',
  indexTitle: '一窗科技-全球首创室内阳光模拟系统',
  home: '首页',
  product: '专业产品',
  application: '应用案例',
  resource: '资源中心',
  aboutUs: '关于我们',
  EWINDOW: '天窗灯',
  EWINDOWSiX: '智能天窗与人本照明',
  coreTechnology: '核心技术',
  applicationHome: '家居',
  office: '办公',
  school: '学校',
  hotel: '展示空间',
  retail: '商业',
  healthcare: '医院疗养',
  underground: '地下空间',
  serviceSupport: '服务与支持',
  daylightInfo: '阳光照明知识',
  downloads: '下载中心',
  lightHealth: '光健康',
  awards: '荣誉奖项',
  contactUs: '联系我们',
  newsCenter: '新闻中心',
  development: '发展历程',
  news: '新闻',
  FAQ: 'FAQ',
  404: '真是抱歉哦，找不到页面',
  backPrevious: '返回上一页',
  backIndex: '返回首页',
  prev: '上一页',
  next: '下一页',
  indexSectionOne: {
    title: '天窗灯',
    one: '室内阳光模拟系统',
    two: '天窗灯是全国首款阳光模拟系统。用灯光的方式模拟阳光穿过天窗照入室内。像家里开了一个真正的天窗。',
    three: '·  模拟蓝天白云、明亮阳光的自然场景。',
    four: '·  模拟阳光光谱，还原阳光的视觉品质。',
    five: '·  模拟阳光早、中、晚的光色变化，体验自然的时间节律。',
  },
  more: '了解更多',
  imgError: '图片占位',
  indexSectionTwo: {
    one: '自然的光谱',
    two: '自然光包含红、橙、黄、绿、蓝、靛、紫等各种颜色，能够反映物体的真实色彩。是光品质最高的光源。天窗灯采用最新LED阳光光谱技术，模拟阳光成分，颜色还原指数95%(阳光为100)。',
    three: '自然的场景',
    four: '天窗灯可以在室内模拟蓝天白云、明亮阳光的光环境。满足人们对于自然、美好生活的畅想，使人可以随时随地享受温暖的阳光。',
    five: '自然的光色变化',
    six: '模拟早、中、晚的光色变化，在室内营造动态的光环境，感受和大自然一样的时间变化，符合人体生物钟的需求。'
  },
  indexSectionTwoTwo: '<p>Natural Light</p><p>自然的光</p>',
  indexSectionThree: {
    one: '<p>Healthy light</p><p>健康的光</p>',
    two: '心理健康',
    three: '沐浴阳光，是一种身心的享受。阳光总是与我们最美丽的记忆联系在一起。是连接室内环境与室外自然的纽带。满足人与自然和谐共处的心理需求。若人们长期生活在缺少阳光的环境中，会造成心情压抑，影响人的心理健康。',
    four: '身体健康',
    five: '现代研究表明，光环境是调节人体生物钟最重要的因素。阳光从日出到日落，周期性的变化可以调节人体生理节律，健康的光环境可以调节人体睡眠，改善抑郁症，缓解阿尔兹海默病等。'
  },
  indexSectionFour: {
    title: '<p>Application case</p><p>全新的应用场景</p>',
    one: '<p>Office</p><p>办公</p>',
    two: '<p>School</p><p>学校</p>',
    three: '<p>Healthcare</p><p>医疗健康</p>',
    four: '<p>Exhibition Space</p><p>展示空间</p>',
    five: '<p>Underground</p><p>地下空间</p>',
    six: '<p>Home</p><p>家居</p>',
    event: '<p>Retail</p><p>商业</p>'
  },
  indexSectionFive: '<p>News</p><p>新闻</p>',
  articleTitle: '新闻 - 一窗科技',
  articleNavs: '新闻中心 > 新闻文章',
  aboutIntroductionTitle: '公司简介',
  aboutIntroductionDes: [
    '深圳市一窗科技有限责任公司是一家创新型的国家高新技术企业，公司以Bring Sunshine to Life为宗旨，通过科技创新，为人们提供更好的照明光环境。公司于2016年在深圳成立。',
    '公司的天窗灯产品，能够在室内模拟蓝天白云，明亮阳光的环境。为采光不足的室内补充阳光，让每个人在家都可以随心所欲的享受阳光，沐浴在温暖的阳光之中。',
    '许多研究显示，阳光是最好的光源，最健康的光源，阳光能够改善人们的身心健康。但随着城市化的发展，阳光已经越来越成为一种稀缺资源。一窗科技应用科技的手段，把阳光重新带回人们的身边。'
  ],
  aboutIntroductionValue: ['创新', '价值', '分享', '团队精神'],
  aboutIntroductionValueDes: [
    '创新是一窗科技的本质属性和价值所在，深深印入我们每一个人的心中',
    '我们的价值来自于创新。我们相信，通过不断地创造性工作，满足人们的需求，也会给我们自己带来价值。',
    '我们乐意与我们的同事，客户分享利益与经验。乐于分享的精神将会让更多的人参与到我们的事业当中，一起把更好的照明体验带给大家。',
    '每一个人的力量是有限的，但我们紧密合作，就出做出伟大的事情。我们相互合作，才能为社会创造更大的价值。'
  ],
  developmentList: [
    ' 天窗灯3.0上市，  共获权30项专利，  3项软件著作权。参加全国照明设计师交流年会，参加中国装饰协会交流年会。',
    '正式进入国内市场，  参加全国照明设计师第十届交流年会。',
    '天窗灯2.0上市，参加香港国际秋季灯饰展上市。',
    ' 荣获深圳市高新技术企业，荣获全国高新技术企业。参加香港秋季灯饰展，法兰克福国际灯光照明展。',
    '天窗灯1.0上市，参加香港国际秋季灯饰展上市，荣获深圳市创新创业大赛大奖，荣获全国创新创业大赛大奖。',
    '一窗科技正式成立， 正式启动天窗灯——阳光模拟系统项目。'
  ],
  awardsList: [
    {
      title: '2017第六届中国创新创业大赛',
      des: '中国创新创业大赛，是由国家科技部、财政部、教育部、国家网信办、全国工商联指导，科技部火炬中心、科技部创新基金管理中心等单位承办。全国最大规模、最高质量、最具影响力的大型创新创业大赛。广受社会各界人士的关注。深圳市一窗科技荣获2017年第六届中国创新创业大赛新能源及节能环保行业全国亚军。'
    },
    {
      title: '2017第九届深圳创新创业大赛',
      des: '深圳创新创业大赛，由深圳市人民政府、科技部火炬高技术产业开发中心主办，是深圳创新的一张名片。历届许多创新项目被深创赛所挖掘，成长为一大批高、精、尖的企业，如大疆创新、创鑫激光、迈测、码隆科技等。深圳市一窗科技荣获2017第九届深圳创新创业大赛大奖。'
    }
  ],
  newsMore: '更多新闻',
  contactInformation: '联系方式',
  phonenamme: '电话',
  email: '邮件',
  addressname: '地址',
  wordTime: '工作时间',
  phoneDetails: '075523711773',
  addressDetails: '深圳市宝安区西乡街道汇潮工业区E栋201',
  wordTimeDetails: '周一至周五， 9:00AM 至 6:00PM',
  theme: '主题',
  user_name: '您的名字',
  address: '您的邮箱',
  phone: '手机号',
  message: '内容',
  pleaseEnter: '请输入',
  pleaseEnterTrue: '输入正确答案',
  success: '操作成功',
  error: '操作失败，请稍后再试',
  reset: '重置',
  submit: '提交',
  applicationIntroductionTitle: '全新的应用效果',
  applicationIntroductionDes: [
    '现代社会，人们有90%的时间都呆在室内，但很多情况下，室内的采光并不充足。人们长期呆在采光不足的室内，会对人的身体和心理都产生不良的影响，如生理时钟紊乱，心情压抑等。一窗科技首创室内阳光模拟系统——天窗灯，把蓝天白云，温暖阳光带入室内。改善室内阳光不足，把自然、健康带回你的身边。'
  ],
  applicationHomeObject: {
    title: '家居案例',
    h2: '阳光照进家庭',
    des: '家庭是我们温暖的港湾，我们都希望把家打造成为一个温暖、健康、舒适的地方。许多针对住宅区的研究表明，自然光是家庭环境最重要的组成因素，有超过60%的人认为自然光非常重要（Finlay,2012). 世界卫生组织一项涉及欧洲8个城市的调查显示：室内采光不足的住户会有很大的风险患有抑郁症和情绪低落（Brown,2011)。'
  },
  applicationHomeOffice: {
    title: '办公室案例',
    h2: '阳光照进办公室',
    des: '现代的办公空间越来越关注职员的办公体验， 求职者也更愿意在办公环境更好的公司就业。  因此很多公司都愿意为员工提供更好的工作环境，  提升员工的办公体验。  但现在的办公大楼很多都是紧密型的大型建筑，  采光作为环境的重要部分， 却很难解决。 天窗灯，能为办公空间带来自然、阳光，提升员工的办公体验。'
  },
  applicationHomeHotel: {
    title: '展示空间案例',
    h2: '阳光照进酒店',
    des: '展示空间需要在特定的区域， 通过空间规划、 平面设计、色彩搭配等设计语言，让客户融入其中，达到展示某种主题的意图。 展示空间做为一个为客户提供体验，  和客户沟通的主要场所， 越来越受到大家的重视。 自然光作为表达空间的一种语言， 可以塑造空间的性格， 形成不同的空间氛围， 实现展示空间的设计意图。'
  },
  applicationHomeRetail: {
    title: '商业中心案例',
    h2: '阳光照进零售中心',
    des: '现代的商业空间，都非常关注如果给客户营造更好的购物体验。因此，如绿植、木材、石材等很多自然元素被应用于商业空间中，有利于改善空间的亲和度。天窗灯可以在室内模拟蓝天白云、明亮阳光，将使得自然元素的应用更加丰富和便利。'
  },
  applicationHomeHealthcare: {
    title: '医院疗养案例',
    h2: '阳光照进康复中心',
    des: '医院的紧张氛围通常给人带来焦虑感，再加上病人本身的身体不适，往往让病人处于巨大的压力之下， 不利于病情康复。 研究显示，阳光可以加快患者的康复速度，一个良好的环境有利于康复疗程，窗户引入的窗外的视野和自然光更有利于营造一个良好的环境氛围，  从而提升康复治疗质量(Vischer 1986; Verderber 1983)。'
  },
  applicationHomeSchool: {
    title: '学校案例',
    h2: '阳光照进学校',
    des: '学校是年轻人成长和接受教育的重要场所，学校的光环境应该重点关注年轻人身心的成长需求。Daystar  1998年的一篇文章“自然光照明的益处”指出，自然光照明能够提高学生和老师的专注力，提高考试成绩，缓解疲劳，促进学校的健康成长。'
  },
  applicationHomeUnderground: {
    title: '地下空间案例',
    h2: '阳光照进地下空间',
    des: '现在别墅地下空间经常作为客厅、 客房， 或休闲、 娱乐空间等， 在寸土寸金的今天， 地下空间的价值越来越被发掘出来， 但地下采光的问题一直没有得到很好的解决。 天窗灯-阳光模拟系统， 可以在室内模拟阳光， 为封闭的地下空间带来活力与生机。'
  },
  clickToView: '点击查看',
  lightHealthList: [
    '自然光对建筑使用者影响的文献综述。',
    '窗户和日照对办公室工作人员整体健康和睡眠质量的影响:一项病例对照试点研究Mohamed Boubekri,Ph.D.，1 Ivy N.张，B.A,2 Kathryn J. Reid, Ph.D.，2 Chia-Hui Wang,1,3 and Phyllis C. Zee, m.d.，Ph.D.， F.A.A.S.M.2',
    '克劳德·罗宾斯(1986)。采光设计与分析。纽约:Van Nostrand Reinhold公司:4-13页。Heerwagen J.H.(1986)。“窗外景色中自然的作用”。1986年国际采光会议论文集Ⅱ。1986年11月4 - 7日;长滩,CA;pp.430 - 437。',
    '维舍尔(1986年11月)。“建筑中采光对居住者行为的影响:新的研究方向”。1986年国际采光会议论文集Ⅱ。加州;pp.419 - 429。Verderber, S.(1983年2月)。"在治疗环境中人类对日光的反应。“1983年国际照明会议。菲尼克斯，AZ:一般程序;pg.415。'
  ],
  dowanload: {
    iphonetext: 'IPhone下载',
    androidtext: 'Android下载',
    iphone: '苹果APP下载',
    android: '安卓APP下载',
    h1: '简洁的操作系统',
    h2: '根据喜好自行设置',
    des: 'APP可以进行更多灵活多样的控制支持IOS8.0及Android4.3以后版本'
  },
  relatedDownloads: '相关下载',
  electronicAlbum: '电子画册',
  promotionalVideo: '宣传视频',
  safetyCertificate: '安全认证证书',
  IESLightingDesign: 'IES照明设计',
  productInstallation: '产品安装',
  circuitVideo: '电路视频',
  wechatCode: '微信关注',
  ewindowSix: {
    title: [
      '阳光生活',
      '阳光对人心理的调节',
      '阳光对人生理的调节'
    ],
    des: [
      '阳光是我们最重要的光源，指引我们知悉这个世界，给予我们光明和温暖，对人的身心健康有着重要的影响。智能天窗-室内阳光模拟系统，能够模拟阳光通过天窗照入室内的照明场景，拥有逼真的蓝天白云和明亮阳光，并能模拟阳光光谱和日升日落的光色变化。为人们带来真实的自然阳光体验，提升室内空间的品质，给人们带来健康的生活环境。',
      '阳光有益于我们的心理健康，阳光是连接室内环境与室外自然环境的纽带，可以抚慰人内心的焦虑、郁闷甚至烦躁，阳光总是与我们最美丽时刻的记忆联系在一起，给予人们安全感、快乐和信心，满足人与自然和谐共处的潜在心理需求。',
      '阳光是人类赖以生存的最重要的自然要素之一。人类在漫长的进化过程中对自然界形成了潜在的依赖，和水、空气一样，阳光对人的身心健康有着不可或缺的作用。阳光有益于我们的身体健康，日升日落形成的周期性光环境变化，形成了人体的生物钟，调节人体内多种激素的分泌与抑制，保障人体的合理的休息与工作、学习精力'
    ],
    section: {
      title: [
        '阳光的变化形成人的生理节奏',
        '人体激素分泌周期'
      ],
      des: [
        '光是调节人体生物钟最重要的因素。符合自然规律的光色变化。有利于调节人体生物钟，缓解夜晚失眠，保持白天充沛精力。',
        '褪黑素、皮质醇是二种受昼夜规律影响，控制人体睡眠和活动能量的人体激素。白天，阳光环境明亮，褪黑素分泌受到抑制，皮质醇分泌高，人体活动精力旺盛。夜晚，光环境减暗，褪黑素分泌增加，皮质醇分泌减少，人体产生睡意，时入休息周期。'
      ]
    }
  },
  learnMore: '了解更多',
  productIntroduction: '产品介绍',
  productIntroductionNav: [
    '产品参数', '智能控制', '安装示意图', '电路图', '电子版画册下载'
  ],
  xiazaihuace: '下载画册',
  nofile: '未找到文件',
  coreTechnologyPage: {
    sectionOne: {
      title: '全国首创—阳光模拟技术',
      des: '阳光是最自然的光源，也是最适合人体视觉的光源。用现代的照明技术模拟阳光，公司开创性地开发了阳光光谱技术、精密光学技术、智能控制技术，以使得天窗灯的照明效果更接近阳光，并获得30多项技术专利和3项软件著作权。'
    },
    sectionTwo: {
      title: '阳光光谱技术',
      des: '天窗灯采用最新的LED阳光光谱技术，利用多种荧光粉匹配调光，在每个光谱区间模拟阳光成份，能够反映物体的真实色彩。颜色还原指数95(阳光为100)，R9亦可达85.'
    },
    sectionThree: {
      title: '精密光学技术',
      des: '自然光由柔和的天窗光和明亮的阳光组成。智能天窗具有二套发光系统，通过微光学技术模拟二种发光模式。 一套模拟蓝天白云发散的天空光， 一套模拟明亮直射的阳光。 二种发光模式结合使用，还原最自然的照明方式。'
    },
    sectionFour: {
      title: '智能控制',
      des: '采用自主研发的算法，天窗灯可以自动模拟一天不同时段的光色变化。在室内营造动态的光色变化。',
      des2: '两种控制模式'
    },
    picOne: {
      title: '自动模式',
      des: '自动模式一天24小时阳光的光色变化'
    },
    picOneTwo: {
      title: '手动模式',
      des: '手动调节个人偏爱的光环境'
    },
    picOneThree: [
      '控制面板',
      'App',
      '有线控制'
    ]
  },
  resourceView: {
    one: '阳光、空气、水被称为生命三要素，对人的身心健康有着重要的影响。',
    two: '阳光可以改善人的心理健康',
    three: '阳光可以调节人的身体健康',
    four: '阳光是调节人体生物钟最重要的因素',
    five: '地球上的所有动画都有一种叫“生物钟”的生理机制，也就是从白天到夜晚的一天24小时循环节律，与地球自转相吻合。',
    six: '2017年诺贝尔生物学或医学奖授予Jeffrey C.Hall、Michael Rosbash和Michael W.Yongoing，已表彰他们发现了生物体昼夜节律的分子机制。',
    seven: '地球上的生命适应了我们星球的旋转。多年来，我们已经知道，包括人类在内活生物体都有一个内部的生物钟，可以帮组他们预测并适应一天中规律节律。Jeffrey C.Hall，Miechael Rosbash和Michael W.Young的研究阐明其内部工作原理。他们的发现解释了植物，动物和人类如何适应他们的生物节律，从而使其与地球的自传同步。（引自诺贝尔奖网：',
    eighth: '阳光调节人体睡眠',
    nine: '睡眠是人类不可缺少的一种生理现象。人的一生中，睡眠占了近1/3的时间，睡眠质量好坏与人体健康与否有着密切的关系。',
    ten: '医学研究表明，偶尔失眠会造成第二天疲倦和动作不协调，长期失眠则会带来注意力不能集中、记忆出现障碍和工作力不从心等后果。失眠已经成为一个影响现代人健康的重要问题。',
    eleven: 'Russell G Foster是牛津大学神经科学教授。全球研究睡眠与昼夜神经科学的权威专家。为我们系统地揭示了光是如何调节调节生物钟，睡眠和24小时昼夜节律的核心机制是如何在人体中枢神经系统内产生和调节；睡觉与精神疾病指尖的关联。Russell说道：“请控制好睡眠，结果，你将成为一个更快，更健康的人。”（引自牛津大学官网：',
    twelve: '光疗法—— 一种全新的精神治疗方法',
    thirteen: '抑郁症是一种综合性的精神障碍疾病，是普通人群中最常见的精神障碍之一。根据世界卫生组织（WHO）与2017年发布《抑郁症及其他常见精神障碍》报告，目前世界范围内预计有超过3亿人饱受抑郁症的困扰。',
    fourteen: '抑郁症的治疗方法包括：药物治疗、心理治疗、电惊厥治疗、光疗法。这些方法可能单独或联合使用。',
    fifteen: '光疗法是让患者置身于特殊的光环境中，特殊的光环境信息会通过非视觉感光系统调节人体生物钟系统，生物钟系统控制人体内多种激素分泌和基因表达，从而让体内激素分泌重新达到平衡，从而起到治疗效果。上世纪八十年代，光疗最早被用于治疗季节性抑郁症，后被逐渐应用于治疗其他非季节性抑郁症。光疗的特点是见效快，副作用小。'
  }
}


export const en = {
  了解天窗灯: "To know EWINLIGHT",
  自然的光: "Natural Light",
  健康的光: "Healthy light",
  全新的应用场景: "Application case",
  领英: "LinkedIn",
  lang: '中文 (中国)',
  image: 'Picture',
  video: 'Video',
  name: 'EWINLIGHT',
  indexTitle: 'Home-EWINLIGHT',
  home: 'Home',
  product: 'Products ',
  application: 'Application',
  resource: 'Resource',
  aboutUs: 'About Us',
  EWINDOW: 'EWINDOW',
  EWINDOWSiX: 'EWINDOW 60',
  coreTechnology: 'Core Technology',
  applicationHome: 'Home',
  office: 'Office',
  hotel: 'Exhibition space',
  retail: 'Retail',
  healthcare: 'Healthcare',
  school: 'School',
  underground: 'Underground',
  serviceSupport: 'service & support',
  daylightInfo: 'Daylight Info',
  downloads: 'Downloads',
  lightHealth: 'Light Health',
  FAQ: 'FAQ',
  awards: 'Award',
  contactUs: 'Contact us',
  newsCenter: 'News',
  development: 'History',
  404: 'So sorry.  can’t find the page',
  backPrevious: 'Back to previous page',
  backIndex: 'Back to Home page',
  news: 'News',
  prev: 'PREW',
  next: 'NEXT',
  indexSectionOne: {
    title: 'EWINDOW',
    one: 'Artificial Daylight System',
    two: 'EWINDOW - One of the earliest Sunlight Simulation System-mimics sunshine shinning indoors through a window. Just like a real skylight.',
    three: '·  High quality sky & clear clouds create natural scene.',
    four: '·  Bright sunshine follows daylight spectrum (IR).',
    five: '·  Simulate the daylight changes from dawn to dusk as the natural time rhythm.',
    six: 'It’s a natural way of lighting, will be an effective approach for designers to release their inspirations and emotions, to introduce daylight-like lighting into architecture for human well-being.'
  },
  more: 'Learn More',
  imgError: 'Picture booth',
  indexSectionTwo: {
    one: 'Natural Spectrum',
    two: 'Natural sunlight spectrum includes various colors such as red, orange, yellow, green, blue, indigo, purple and so on, which can reflect the true colors of objects. The EWINDOW - Artificial daylight system - adopts the latest LED, sunlight spectrum technology, which simulates the sunlight, and the color reproduction index is 95% (sunlight is 100).',
    three: 'Natural scene',
    four: 'EWINDOW - Artificial daylight - uses technologies to simulate daylight, bringing the blue sky, white clouds, and bright sunshine indoors. Integrating with other natural elements to bring a relatively natural and comfortable environment to fulfill people’s dream for natural and beautiful life. And enjoying warm sunshine anytime & anywhere.',
    five: 'Natural changes in light and color',
    six: 'EWINDOW - Artificial daylight - Simulates sunlight, Synchronizes 24 hours sunlight cycle, creates dynamic atmosphere change in the room, in line with the needs of human circadian physiological rhythm.'
  },
  indexSectionTwoTwo: '<p>Natural Light</p>',
  indexSectionThree: {
    one: '<p>Healthy light</p>',
    two: 'Daylight is good for Mental health',
    three: 'Soaking in the sun is a kind of physical and mental enjoyment. Sunshine is always associated with our most beautiful memories. It is a link between indoor environment and outdoor nature. To meet the psychological needs of harmonious coexistence between human and nature. If people live in an environment that is lack of sunshine for a long time, it will cause mood depression, affect people’s mental health. EWINDOW - Artificial daylight - will make a big contribution to improve this.',
    four: 'Daylight is good for Physical health',
    five: 'Nowadays, research shows that the light environment is the most important factor in regulating the biological clock. The periodic change of sunlight from sunrise to sunset can regulate human physiological rhythm. A healthy light environment can regulate human sleep, improve depression, relieve Alzheimer’s disease and so on. EWINDOW - Artificial Daylight - will be a great help on this.'
  },
  indexSectionFour: {
    title: '<p>Application</p>', 
    one: '<p>Office</p>',
    two: '<p>School</p>',
    three: '<p>Hospital</p>',
    four: '<p>Exhibition Space</p>',
    five: '<p>Underground</p>',
    six: '<p>Home</p>',
    event: '<p>Retail</p>'
  },
  indexSectionFive: '<p>News</p>',
  articleTitle: 'News - EWINLIGHT',
  articleNavs: 'News center > News articles',
  aboutIntroductionTitle: 'Company profile',
  aboutIntroductionDes: [
    'EWINLIGHT, as a innovative company, our core mission is to provide people with a better lighting experience through technological innovation, The company was founded in Shenzhenin 2016.Shenzhen Ewinlight Technology Co., Ltd. is an innovative company, our core mission is to provide people with better lighting experience through scientific and technological innovation.',
    'Our first product is the EWINDOW - Smart Artificial Daylight System. Smart skylight is the world’s first indoor sunlight simulation system. The idea of the product is to simulate the scene of sunlight lighting indoor by artificial means. Let People at home can follow their inclinations to enjoy the sunshine, bathed in the warm sunshine. Supplement sunlight for under-lit rooms, supplement sunlight for inclement weather, and supplement sunlight for areas with short sunshine hours.',
    'Many studies show that sunlight is our best source and most healthiest of light, can improve our physical and mental health. With the development of urbanization and climate change, sunlight has increasingly become ascarce resource. EWINLIGHT use science and technology to Bring Sunshine to People’s Life.'
  ],
  aboutIntroductionValue: ['Innovation', 'Make Value', 'Share Benefits', 'Teamwork Spirit'],
  aboutIntroductionValueDes: [
    'Innovation is the essential attribute and value of EWINLIGHT, which has been deeply placed in everybody of our team.',
    'Our value comes from innovation. We believe that by constantly working creatively to meet people’s needs, we can also bring value to ourselves.',
    'We are willing to share benefits and experience with our colleagues and customers. The spirit of sharing will enable more people to participate in our case and bring better lighting experience to everyone together.',
    'The power of each individual is limited, but when we work together, we can do great things. Only when we cooperate with each other can we create greater value for society.'
  ],
  developmentList: [
    'Artificial Daylight EWINDOW3.0 will be launched in the beginning of 2022, with 30 patents and 3 software Copyrights in total. Participated in the National Lighting Designers Exchange Annual meeting, participated in the China Decoration Association exchange annual meeting.',
    'Enter the domestic market formally, participated in the 10th national lighting designers exchange annual meeting.',
    'Artificial Daylight EWINDOW2.0 was launched in the year of 2019, attended the Hong Kong International Lighting Fair (Autumn ).',
    'Won the Shenzhen high-tech enterprise, won the national high-tech enterprise, participated in the Hong Kong Lighting Exhibition (Autumn), and the Frankfurt Light+Building Exhibition.',
    'Artificial Daylight EWINDOW1.0 was launched in the year of 2017, attended the Hong Kong International Lighting Exhibition (Autumn), and won the grand Prize of Shenzhen Innovation and Entrepreneurship Competition and National Innovation and Entrepreneurship Competition.',
    'EWINLGITH technology officially established, officially launched EWINDOW - Artificial Daylight System.'
  ],
  awardsList: [
    {
      title: 'The 6th China Innovation and Entrepreneurship Competition 2017.',
      des: 'The China Innovation and Entrepreneurship Competition is guided by the Ministry of Science and Technology, the Ministry of Finance, the Ministry of Education, the Cyberspace Administration of China, the All-China Federation of Industry and Commerce, and undertaken by the Torch Center of the Ministry of Science and Technology and the Innovation Fund Management Center of the Ministry of Science and Technology. The largest, highest quality, and most influential large-scale innovation and entrepreneurship competition in China. It is widely concerned by people from all walks of life. EWINLGITH was awarded the national runner-up of the 6th China Innovation and Entrepreneurship Competition of New energy and Energy Conservation and environmental protection industry in 2017.'
    },
    {
      title: 'The 9th Shenzhen Innovation and Entrepreneurship Competition 2017.',
      des: 'The Shenzhen Innovation and Entrepreneurship Competition, sponsored by Shenzhen Municipal People’s Government and Torch High-tech Industry Development Center of Ministry of Science and Technology, is a name card of Shenzhen innovation. Many previous innovative projects have been excavated by The Shenchuang Competition, and have grown into a large number of high, fine and cutting-edge enterprises, such as DJI Innovation, Chuangxin Laser, Maizong, And Malong Technology.EWINLGITH won the grand Prize of the 9th Shenzhen Innovation and Entrepreneurship Competition in 2017.'
    }
  ],
  newsMore: 'More News',
  addressname: 'Address',
  email: 'Email',
  phonenamme: 'Tel',
  wordTime: 'Working time',
  contactInformation: 'Contact Us',
  phoneDetails: '+86 755 23711609',
  addressDetails: 'Add: ROOM 201, Building E, Huichao Industrial Center, Guxing Community , Xixiang Street, Baoan, Shenzhen, Guangdong, P.R.C 518126',
  wordTimeDetails: 'Monday to Friday 9:00AM to 6:00PM',
  theme: 'Subject ',
  user_name: 'Your Name',
  address: 'Your Email',
  phone: 'Your Mobile Number ',
  message: 'Text',
  pleaseEnter: 'Please Enter',
  pleaseEnterTrue: 'Enter the correct answer',
  success: 'Operation succeeded',
  error: 'The operation failed. Please try again later',
  reset: 'Reset',
  submit: 'Submit',
  applicationIntroductionTitle: 'Better Effect in Application',
  applicationIntroductionDes: [
    'New Application Effects In modern society, people spend 90% of their time indoors, but in many cases, indoor lighting is not sufficient. People staying in a room with insufficient daylight for a long time will cause adverse effects on people’s body and psychology, such as disturbance of the biological clock and depression of the mood. EWINLIGHT  pioneered an indoor sunlight simulation system-smart Artificial Daylight, which brings the blue sky, white clouds and warm sunlight into the room. Improve the environment which is  lack of indoor sunlight and bring nature and health back to life.'
  ],
  applicationHomeObject: {
    title: 'Home Application',
    h2: 'Daylight at Home',
    des: 'Family is our warm harborand we all hope to make our home a warm, healthy, and comfortable place. Many studies on residential areas have shown that natural light is the most important component of the family environment, more than 60% of people think natural light is very important (Finlay, 2012). A survey by the World Health Organization involving 8 cities in Europe shows: indoor lighting Insufficient households are at great risk of depression and depression (Brown, 2011). Sunshine is so important to our daily life. Enjoying the sunshine on rainy days, snowy days, cloudy days and short daytime of winter will be a wonderful thing.EWINDOW - the Artificial Daylight - is suitable for Residential in Living Room, Bed Room, Checkroom, Dining Room, Bath Room, Reading Room, Children’s Room, Basement Floor and any room without a window.'
  },
  applicationHomeOffice: {
    title: 'Office Application',
    h2: 'Daylight in Office',
    des: 'In today’s industrialized society, most people need to work intensively in the office for a long time. The focus of office lighting should be to improve work efficiency and reduce work pressure. Natural light is conducive to maintaining the health of employees, and it also has a certain healing effect on certain chronic diseases. The pleasant atmosphere created by natural light reduces the work pressure of office workers, promotes the health of employees, and improves work efficiency and economic benefits (L. Edwards and P. Torcellini, 2002). EWINDOW as the artificial daylight system simulates sunlight for 24 hours, brings sunshine into the office. Sunshine is so important in our daily life. Working time takes 1/3 of our time every day. Enjoy the sunshine in every working minute. Everyone will work happily and efficiently. EWINDOW is suitable for Office of the open work area, single office, meeting room, restroom, and any room without a window.'
  },
  applicationHomeHotel: {
    title: 'Exhibition Space Project',
    h2: 'Daylight in Hotel',
    des: 'The exhibition space needs to be in a specific area, through different design languages, let customers integrate into it to achieve the intention of displaying the theme. As a main place to provide customers with experience and communicate with customers, the exhibition space is receiving more and more attention. As a tool of expressing space, daylight can shape the character of space and form space atmospheres.'
  },
  applicationHomeRetail: {
    title: 'Commercial Application',
    h2: 'Daylight in Retail',
    des: 'Blue sky, sunshine, green plants, and stones form an independent natural area in the commercial space. Soften the business atmosphere and create a friendly shopping experience that immerses customers. To provide customers with an inviting, pleasant environment, bringing sunlight into the interior, creating a more comfortable shopping environment. Lighting is" a completely different way of retail", says the lighting consultant of Clanton Engineering Inc. The light illuminates the ceiling and brings people into the store (Hennesy 1996).'
  },
  applicationHomeHealthcare: {
    title: 'Healthcare Application',
    h2: 'Daylight in Healthcare',
    des: 'The tense atmosphere of the hospital usually brings people a sense of anxiety, coupled with the patient’s physical discomfort, often let patients under great pressure, which is not conducive to recovery. Studies have shown that sunlight can speed up the recovery of patients, and a good environment is conducive to the rehabilitation process. The view and natural light introduced by EWINDOWs are more conducive to creating a good environment, thus improving the quality of rehabilitation treatment (Vischer 1986; Verderber 1983).'
  },
  applicationHomeSchool: {
    title: 'School',
    h2: 'Daylight in School',
    des: 'School is an important place where children grow up and accept education. The lighting for school should be qualified and pay attention to the growth on physiology body and psychology. A Daystar article,” Benefits of Natural Daylighting”(1998), states that there is increased student and teacher attendance , increased achievement rates, reduced fatigue factors, improved student health, and enhancement of general development.'
  },
  applicationHomeUnderground: {
    title: 'Underground Application',
    h2: 'Daylight Underground',
    des: 'Recently, the underground space of villas is often used as a living room, guest room, leisure and entertainment space, etc. Nowadays, the value of underground space is more and more explored, but the problem of underground lighting has not been well solved. EWINDOW - sunlight simulation system-can simulate the sunlight in the indoor, bring vitality and vitality to the closed underground space.'
  },
  clickToView: 'Click To View',
  lightHealthList: [
    'A Literature Review of the Effects of Natural Light on Building Occupants. L. Edwards and P. Torcellini',
    'Impact of Windows and Daylight Exposure on Overall Health and Sleep Quality of Office Workers: A Case-Control Pilot Study. Mohamed Boubekri, Ph.D.,1 Ivy N. Cheung, B.A.,2 Kathryn J. Reid, Ph.D.,2 Chia-Hui Wang,1,3 and Phyllis C. Zee, M.D., Ph.D., F.A.A.S.M.2',
    'Robbins , Claude L. (1986). Daylighting Design and Analysis. New York: Van Nostrand Reinhold Company: pp. 4-13.Heerwagen, J.H. (1986). “The Role of Nature in the View from the Window.” 1986 International Daylighting Conference ProceedingsⅡ. November 4-7, 1986; Long Beach, CA; pp.430-437.',
    'Vischer, J.C. (November 1986). “The Effects of Daylighting on Occupant Behavior in Buildings: New Directions for Research.” 1986 International Daylighting Conference Proceedings Ⅱ. California; pp.419-429.Verderber, S. (February 1983). “Human Response to Daylighting in the Therapeutic Environment.” 1983 International Daylighting conference. Phoenix, AZ: General Proceedings; pg.415.'
  ],
  dowanload: {
    iphonetext: 'IPhone Download',
    androidtext: 'Android Download',
    iphone: 'Apple App Download',
    android: 'Android Download',
    h1: 'Simple Operation,',
    h2: 'set your own preference',
    des: 'APP can be more flexible control, support IOS8.0 and ANDROID4.3 later versions'
  },
  relatedDownloads: 'Related to download',
  electronicAlbum: 'Catalogue',
  promotionalVideo: 'Promotion Video',
  safetyCertificate: 'Safety certificate',
  IESLightingDesign: 'IES Lighting Design',
  productInstallation: 'Product Installation',
  circuitVideo: 'Circuit Video',
  wechatCode: 'Wechat Code',
  ewindowSix: {
    title: [
      'Daylight & EWINDOW',
      'Natural View',
      'Natural Rhythms'
    ],
    des: [
      'Daylight is the most important lighting source to us, has accompanied us for centuries. Nowadays, studies have showed that daylight has great impact on our health, not only just give us light, but also improve our mood and physical health. EWINDOW is an artificial daylight system, follows the features of daylight, aim to complement daylighting in many cases. Because daylight vary significantly with time, seasons, latitude and architecture structure.',
      'Meeting the need for contact with the outside living environment is an important psychological aspect linked to daylight (Robbins, 1986). Because ntural views tend to produce positive responses, they may be more effective in reducing stress, decreasing ansiety, holding attention, and improving mood. Studies in 1979, 1981, and 1986 by Ulrich (Heerwagen 1986) support the effectiveness of natural views. EWINDOW provide a vivid scene with sky, clouds and sunshine, reproduce daylight environment by man-made.',
      'Almost everything under the sun follows the rhythms of the sun. The biological clock is involved in many aspects of our complex physiology. We now know that all multicellular organisms, including humans, utilize a similar mechanism to control circadian rhythms. Alarge proportion of our genes are regulated by the biological clock and , consequently, a carefully calibrated circadian rhythm adapts our physiology to the different phases of the day (Nobel Prize in Physiology or Medicine 2017).EWINDOW create a stronge daylight-like evironment, and it can synchronize the lighting ambient to the sun in 24 hours. It correspond with the natural daylight rhythms, means it correspond with our circadian system.'
    ],
    section: {
      title: [
        'The change of sunlight forms the physiological rhythm of adults',
        'Human hormone secretion cycle'
      ],
      des: [
        'Light is the most important factor in regulating the bodys biological clock. Light color changes in accordance with natural laws. It is conducive to regulating the bodys biological clock, alleviating insomnia at night and maintaining full energy during the day.',
        'Melatonin and cortisol are two human hormones that control human sleep and activity energy affected by the law of day and night. During the day, the sunshine environment is bright, melatonin secretion is inhibited, cortisol secretion is high, and human activity energy is vigorous. At night, the light environment darkens, the secretion of melatonin increases, the secretion of cortisol decreases, the human body produces drowsiness and enters the rest cycle.'
      ]
    }
  },
  learnMore: 'Learn More',
  productIntroduction: 'Product Introduction',
  productIntroductionNav: [
    'Parameters', 'Intelligent Control', 'Installation Diagram', 'Circuit Diagram', 'Download'
  ],
  xiazaihuace: 'Download',
  nofile: 'No Files Found',
  coreTechnologyPage: {
    sectionOne: {
      title: 'National Initiative - Sunshine Simulation Technology',
      des: 'Sunlight is the most natural light source and the most suitable light source for human vision. Using modern lighting technology to simulate sunlight, the company creatively developed sunlight spectrum technology, precision optical technology and intelligent control technology to make the lighting effect of skylight lamp closer to sunlight, and obtained more than 30 technical patents and 3 software copyrights.'
    },
    sectionTwo: {
      title: 'Sunlight Spectrum Tchnology',
      des: 'The skylight lamp adopts the latest LED sunlight spectrum technology, uses a variety of phosphors to match the dimming, simulates the sunlight component in each spectral interval, and can reflect the real color of the object. The color reduction index is 95 (sunlight is 100), and R9 can also reach 85'
    },
    sectionThree: {
      title: 'Precision Optical Technology',
      des: 'Natural light consists of soft skylight light and bright sunlight. The intelligent skylight has two sets of light-emitting systems, which simulate two light-emitting modes through micro optical technology. One simulates the sky light emitted by blue sky and white clouds, and the other simulates the bright and direct sunlight. The two lighting modes are combined to restore the most natural lighting mode.'
    },
    sectionFour: {
      title: 'Intelligent Control',
      des2: 'Two control modes',
      des: 'Using the self-developed algorithm, the skylight lamp can automatically simulate the light color changes in different periods of the day. Create dynamic light color changes indoors.'
    },
    picOne: {
      title: 'Automatic Mode',
      des: 'Automatic mode changes the light color of the sun 24 hours a day'
    },
    picOneTwo: {
      title: 'Manual Mode',
      des: 'Manually adjust personal preferred light environment'
    },
    picOneThree: [
      'Control Panel',
      'App',
      'Wired Control'
    ]
  },
  resourceView: {
    one: "The three elements of Life are basically comprised of Sunshine, Fresh Air as well as Water. Sunlight have an important impact on people’s physical and mental health.",
    two: "Sunlight can improve people’s psychological health",
    three: "Sunlight can regulate people's physiological health",
    four: "Sunlight is the most important factor in biological clock",
    five: "All animals on Earth have a biological mechanism called a clock, a 24-hour cycle from day to night that coincides with the earth's rotation.",
    six: 'The 2017 Nobel Prize in Physiology or Medicine has been awarded to Jeffrey C. Hall, Michael Rosbash and Michael W. Young for their discoveries of the molecular mechanisms underlying circadian rhythms in living organisms.',
    seven: "Life on Earth adapted to the rotation of our planet. We've known for years that living organisms, including humans, have an internal clock that helps them anticipate and adapt to regular rhythms throughout the day. Studies by Jeffrey C. Hall, Michael Rosbash, and Michael W. Young shed light on its inner workings. Their findings explain how plants, animals and humans adapt their biorhythms to synchronize them with the earth's rotation. (quoted from the Nobel Prize's official website: ",
    eighth: 'Sunlight regulates sleep',
    nine: "Sleep is an indispensable physiological phenomenon for human beings. People's life, sleep accounts for nearly 1/3 of the time, the quality of sleep and human health is closely related.",
    ten: "Medical studies have shown that occasional insomnia can lead to fatigue and uncoordinated movements the next day, while chronic insomnia can lead to problems concentrating, memory problems and inability to perform at work. Insomnia has become an important problem affecting modern people's health.",
    eleven: "Russell G Foster is professor of neuroscience at Oxford University. The world's leading expert on the neuroscience of sleep and day and night. It systematically reveals how light regulates the body clock and how the core mechanism of sleep and 24-hour circadian rhythm is generated and regulated in the human central nervous system. The association between sleep and mental illness. Control your sleep, and you'll be a happier, healthier person as a result, Russell says. (From Oxford University website:",
    twelve: 'Light therapy -- a new method of mental therapy',
    thirteen: 'Depression is a comprehensive mental disorder and one of the most common mental disorders in the general population. According to the Report "Depression and Other Common Mental Disorders" released by the World Health Organization (WHO) in 2017, it is estimated that more than 300 million people worldwide suffer from depression.',
    fourteen: 'Treatments for depression include medication, psychotherapy, electroconvulsive therapy, and light therapy. These methods can be used individually or in combination.',
    fifteen: "Light therapy is to expose patients to a special light environment, and the special light environment information will regulate the body clock system through the non-visual photosensitive system. The body clock system controls the secretion of various hormones and gene expression in the body, so as to re-balance the hormone secretion in the body, so as to achieve the therapeutic effect. Light therapy was first used to treat seasonal depression in the 1980s, and has since been used to treat other non-seasonal depression. Light therapy is characterized by quick results and few side effects."
  }
}