

/**
 * 返回数据的类型
 * @param value 需要判断的值
 * @return string
*/
export const isTypeOf = (value) => {
  const resultType = Object.prototype.toString.call(value)
  const reslutOject = {
    '[object Null]': 'null',
    '[object Undefined]': 'undefined',
    '[object String]': 'string',
    '[object Number]': 'number',
    '[object Boolean]': 'boolean',
    '[object Function]': 'function',
    '[object Date]': 'date',
    '[object Array]': 'array',
    '[object RegExp]': 'regExp',
    '[object Object]': 'object',
    '[object JSON]': 'json',
    '[object Set]': 'set',
    '[object Map]': 'map',
    '[object Symbol]': 'symbol',
    '[object Error]': 'error'
  }
  return reslutOject[resultType]
}

/* 返回任意数据的原始类型 */
export const rawType = (value) => {
  if (['date', 'math', 'error', 'null', 'undefined', 'Proxy'].includes(isTypeOf(value))) {
    return value
  } else {
    const Ctor = value.constructor
    return new Ctor()
  }
}

/**
  * 深度复制
  * @param value 需要深度赋值得值
*/
export const deepClone = (value) => {
  let result = rawType(value)
  const type = isTypeOf(value)
  if (type === 'array') {
    result = value.map((element) => {
      element = deepClone(element)
      return element
    })
  } else if (type === 'object') {
    Object.keys(value).forEach(element => {
      Reflect.set(result, element, deepClone((value[element])))
    })
  } else if (type === 'set') {
    value.forEach((element) => {
      result.add(deepClone(element))
    })
  } else if (type === 'map') {
    value.forEach((value, key) => {
      result.set(key, deepClone(value))
    })
  } else if (['date', 'math', 'error', 'null', 'undefined'].includes(type)) {
    result = value
  } else {
    result = value.valueOf()
  }
  return result
}

/**
 * 抖动处理
 * @param predicate 执行的函数
 * @param wait 等待的时间 默认300ms
 * @param immediate 是否立即执行， 默认false
 */
export const debounce = (predicate, wait = 300, immediate = false) => {
  let time = null
  let timestamp = 0
  let args
  const later = () => {
    const last = Date.now() - timestamp
    if (last < wait && last > 0) {
      time = window.setTimeout(later, wait - last)
    } else {
      predicate.apply(this, args)
      window.clearTimeouttime
      time = null
    }
  }

  return (...arg) => {
    args = arg
    if (immediate) {
      predicate.apply(this, args)
      return false
    }
    timestamp = Date.now()
    if (!time) {
      time = window.setTimeout(later, wait)
    }
  }
}

/**
 * 对指定数据增加特殊符号，如%
 * @param value 需要处理的数据 array | object | string | number
 * @param predicate  需要执行判断的函数，如对value * 100 或增加千位符 默认为增加%号
 * @param prop 当处理的类型为数组或者对象时，需要处理的key，对象才生效 Array<string>
 * @retrun T
*/
export const addSymbol = (value, predicate = markValue, prop) => {
  if (isTypeOf(predicate) !== 'function') {
    throw new TypeError('predicate must be a function')
  }

  const type = isTypeOf(value)
  let result
  if (type === 'string' || type === 'number') {
    result = predicate(value)
  } else if (['null', 'undefined'].includes(type)) {
    result = 0
  } else {
    if (type === 'object') {
      result = deepClone(value)
      const keysArray = Object.keys(value)
      keysArray.forEach(key => {
        if (prop?.includes(key)) Reflect.set(result, key, addSymbol(result[key], predicate, prop))
      })
    } else {
      result = value.map(ele => {
        return addSymbol(ele, predicate, prop)
      })
    }
  }

  return result
}

/**
 * 对指定内容增加特殊符号
 * @param value 需要处理的数据 number | string 可选为addSymbol的predicate参数
 * @param mark 需要增加的符号，默认为%
 * @retrun string
*/
export const markValue = (value, mark = '%') => {
  return value + mark
}

/**
 * 对指定内容增加特殊符号
 * @param value null | undefined | ''， 进行处理
 * @param toValue 默认为 O
*/
export const nullToZero = (value, toValue = 0) => {
  return value || toValue
}

/**
 * 对数字或字符串增加千分符
 * @param value 需要处理的数据 number | string 可选为addSymbol的predicate参数
 * @param mark  千分符的样式默认为，
 * @retrun string
*/
export const micrometer = (value, mark = ',') => {
  const result = value.toString().replace(/\d+/, n => {
    return n.replace(/(\d)(?=(\d{3})+$)/g, ($1) => {
      return $1 + mark
    })
  })

  return result
}

/**
 * 对多个异步的Promise进行统一处理
 * 当一个页面有多个Ajax时，我们建议你通过该方法统一获取来控制loadding
 * @param promise 为当前需要执行的函数组
 *  请注意promise为函数的执行结果，promiseAll内部不做调用
 * @retrun Promise<PromiseAll>
*/

export const promiseAll = async(promise) => {
  try {
    const result = await Promise.all(promise)
    return {
      isSuccess: true,
      result: result
    }
  } catch (err) {
    return {
      isSuccess: false,
      result: err
    }
  }
}

/**
 * 获取指定样式的数字值
 * @param dom html HTMLElement
 * @param propertyname 属性名
 * @param company 单位 默认为px
 * @retrun number
*/
export const getStyleAsNumber = (dom, propertyname, company = 'px') => {
  const main = getComputedStyle(dom)
  return Number(main.getPropertyValue(propertyname).split(company)[0])
}

/**
 * 首字母大写
 * @param value 需要转换的单词
 * @retrun string
*/
export const initialsCase = value => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

/**
 * 延迟执行
 * @param time 需要延迟的时间 ms 默认为300
 * @retrun Promise<'success'>
*/
export const sleep = (time = 300) => {
  return new Promise(suc => {
    setTimeout(() => {
      suc('success')
    }, time)
  })
}



/**
 * 对图片进行Promise下载
 * @param src 需要下载的图片地址
*/
export const imageLoad = src => {
  return new Promise((suc, err) => {
    const img = new Image()
    img.onload = () => suc(img)
    img.onerror = (error) => err(error)
    img.src = src
  })
}

/**
 * blob异步生成
*/
export const promiseBlob = value => {
  return new Promise((suc, err) => {
    value.toBlob(blob => {
      blob ? suc(blob) : err(null)
    })
  })
}

/**
 * 前端对文件进行下载
 * @param fileName 下载后的文件名称
 * @param file 下载的地址，string 可以文二进制文件，也可也为可预览的文件
 */
export const savaFile = (fileName = 'file', file) => {
  const event = new MouseEvent('click')
  const save_link = document.createElement('a')
  save_link.href = file
  save_link.download = fileName
  save_link.dispatchEvent(event)
}



// 设置 rem 函数
export const setRem = () => {
  const htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  const htmlDom = document.getElementsByTagName('html')[0];
//设置根元素字体大小
  htmlDom.style.fontSize= htmlWidth / 20 + 'px';
}