<template>
  <div class="footer-wrapper">
    <div class="main">
      <img src="@/assets/image/logo-color.png" >
      <div class="main-content">
        <template
          v-for="(item, index) in configLink"
        >
          <div
            :key="index"
            class="main-content-list"
            @click="toLink(item.link)"
          >
            <div>{{ $t(item.prop) }}</div>
            <img src="@/assets/image/icon/icon_arrow_right_h24@2x.png" >
          </div>
        </template>
        <div
          @click="$i18n.locale = $i18n.locale === 'zh' ? 'en' : 'zh'"
          class="main-content-list"
        >
          <div>{{ $t('lang') }}</div>
        </div>
      </div>
    </div>
    <div class="put-on-record">
      <a href="https://beian.miit.gov.cn/" target="_blank">Copyright-Ewinlight 2019  |  粤ICP备15016492号-1 </a>
    </div>
  </div>
</template>

<script>
import { configLink } from '@/utils/config'
export default {
  data() {
    return {
      configLink: [
        {
          prop: 'home',
          link: '/index'
        },
        ...configLink
      ]
    }
  },
  methods: {
    toLink(link) {
      this.$router.push(link)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-wrapper {
  width: 100%;
  overflow: hidden;
  border-top: 1px solid rgba(225, 225, 225, 0.8);
  .main {
    background: #fff;
    padding: 20px;
    padding-top: 37px;
    padding-bottom: 0;
    & > img:nth-child(1) {
      height: 26px;
      display: block;
      margin-bottom: 26px;
    }
    &-content {
      width: 100%;
      &-list {
        display: flex;
        height: 56px;
        border-bottom: 1px solid rgba(225, 225, 225, 0.8);
        font-size: 14px;
        color: #222222;
        line-height: 22px;
        align-items: center;
        justify-content: space-between;
        img {
          width: 24px;
        }
      }
    }
  }
  .put-on-record {
    color: rgba(136, 136, 136, 1);
    font-size: 11px;
    height: 56px;
    line-height: 56px;
    background: rgba(251, 251, 251, 1);
    padding-left: 20px;
    a {
      color: rgba(136, 136, 136, 1);
      text-decoration: none;
    }
  }
}
</style>