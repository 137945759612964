import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './permission'
import MetaInfo from 'vue-meta-info'
import VueI18n from 'vue-i18n'
import { zh, en } from '@/utils/translate'
import './plugins/element.js'
import '@/utils/pxtorem.js'
import 'swiper/css/swiper.css'

if (!(navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i)))  location.href = process.env.VUE_APP_redirect

Vue.config.productionTip = false
Vue.use(MetaInfo)
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  messages: {
    'zh': zh,
    'en': en
  }
});

new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')

