

/**
 * 对小于10的数字进行补零
 * @param num 需要补零的数
 * @return Object
 */
export const makeUpZero = (num) => {
  const result = num < 10 ? '0' + num : '' + num + ''
  return result
}

/**
 * 获取当前时间 或 指定时间的结果 的时分秒
 * @param date '需要转换的时间' 默认为当前时间
 * @param interval 间隔符号类型 '- | /' 日期间拼接符号类型
 * @return Object
 */
export const timeObject = (date = new Date(), interval = '/') => {
  const time = new Date(date)
  const year = '' + time.getFullYear() + ''
  const month = makeUpZero(time.getMonth() + 1)
  const day = makeUpZero(time.getDate())
  const hour = makeUpZero(time.getHours())
  const minute = makeUpZero(time.getMinutes())
  const second = makeUpZero(time.getSeconds())
  const weekArray = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  const week = weekArray[time.getDay()]
  const weekNum = time.getDay()
  const yearMonth = year + interval + month
  const monthDay = month + interval + day
  const yearMonthDay = yearMonth + interval + day
  const yearMonthDayHour = yearMonthDay + ' ' + hour + ':' + minute + ':' + second
  const HMS = hour + ':' + minute + ':' + second
  const stampMill = time.getTime()
  const stamp = Math.floor(stampMill / 1000)
  const result = {
    year: year,
    month: month,
    day: day,
    hour: hour,
    minute: minute,
    second: second,
    week: week,
    weekNum: weekNum,
    yearMonth: yearMonth,
    monthDay: monthDay,
    yearMonthDay: yearMonthDay,
    yearMonthDayHour: yearMonthDayHour,
    HMS: HMS,
    stamp: stamp,
    stampMill: stampMill,
    format: yearMonthDay,
    formatHMS: yearMonthDayHour,
    YM: yearMonth,
    YMS: yearMonth + interval + '01',
    default: time
  }

  return result
}

/**
 * 获取当前时间 或 指定时间的结果
 * @param type 类型 '时间戳(秒) | 时间戳(毫秒) | 格式化时间(如2020-01-01)不带分秒时 | 默认时间 | 格式化时间带小时 | 仅仅分秒时 | 年 | 月 | 日 | 年月 | 年月日（当月第一天）
 * @param date '需要转换的时间' 默认为当前时间
 * @param interval 间隔符号类型 '- | /' 当类型为格式化时间时 拼接符号类型
 * @return result
 */

export function formatTime (type, date = new Date(), interval = '-') {
  const time = new Date(date)
  const timeResult = timeObject(time, interval)
  return timeResult[type || 'format']
}