<template>
  <div class="components-header">
    <div
      class="components-header-wrapper"
    >
      <div class="header-mian">
        <img src="@/assets/image/logo-color.png" >
        <img @click="isShow = true" src="@/assets/image/icon/icon_menu_h18@2x.png" >
      </div>
    </div>
    <el-drawer
      :visible.sync="isShow"
      size="7rem"
    >
    <div @click="isShow = false" class="header-content">
      <template
        v-for="item in headerList"
      >
        <router-link
          :key="item.prop"
          :to="item.link"
          exact
          class="header-content-list-tabs"
          tag="div"
        >
          {{ $t(item.prop) }}
        </router-link>
      </template>
      <div
        @click="$i18n.locale = $i18n.locale === 'zh' ? 'en' : 'zh'"
        class="header-content-list-tabs"
      >
        {{ $t('lang') }}
      </div>
    </div>
    </el-drawer>
  </div>
</template>
<script>
import {configLink} from '@/utils/index'
export default {
  name: 'ComponentsHeader',
  data: () => {
    return {
      isShow: false,
      scrollTop: 0,
      headerList: [
        {
          prop: 'home',
          link: '/index'
        },
        ...configLink
      ]
    }
  },
  mounted() {
    document.addEventListener('scroll', this.mainScroll)
  },

  methods: {
    mainScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTop = scrollTop
    }
  }
}
</script>

<style lang="scss" scoped>
.components-header {
  &-wrapper {
    width: 375px;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    height: 56px;
    background: #fff;
    .header-mian {
      width: 100%;
      height: 56px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      img {
        &:nth-child(1) {
          height: 26px;
        }
        &:nth-child(2) {
          height: 18px;
        }
      }
    }
  }
  ::v-deep .header-content {
    width: 100%;
    padding-right: 64px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &-list-tabs {
      font-size: 16px;
      color: #222222;
      line-height: 16px;
      margin-bottom: 40px;
      padding-right: 20px;
      border-right: solid 2px #fff;
      &:last-child {
        margin-bottom: 0;
      }
      &.router-link-active {
        font-weight: bold;
        border-right: solid 2px #222222;
      }
    }
  }
}

</style>