import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: () => import('../views/article/index.vue')
  },
  {
    path: '/about',
    name: 'About',
    redirect: '/about/index',
    component: () => import('@/views/about/index.vue'),
    children: [
      {
        path: 'index',
        name: 'AboutIndex',
        component: () => import('@/views/about/components/index.vue')
      },
      {
        path: 'news',
        name: 'AboutNews',
        component: () => import('@/views/about/components/news.vue')
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('../views/contact/index.vue')
      },
    ]
  },
  {
    path: '/application',
    name: 'Application',
    redirect: '/application/index',
    component: () => import('@/views/application/index.vue'),
    children: [
      {
        path: 'index',
        name: 'ApplicationIndex',
        component: () => import('@/views/application/components/index.vue')
      },
      {
        path: 'home',
        name: 'ApplicationHome',
        component: () => import('@/views/application/components/home.vue')
      },
      {
        path: 'office',
        name: 'Applicationoffice',
        component: () => import('@/views/application/components/office.vue')
      },
      {
        path: 'school',
        name: 'ApplicationSchool',
        component: () => import('@/views/application/components/school.vue')
      },
      {
        path: 'healthcare',
        name: 'ApplicationHealthcareS',
        component: () => import('@/views/application/components/healthcare.vue')
      },
      {
        path: 'retail',
        name: 'ApplicationRetail',
        component: () => import('@/views/application/components/retail.vue')
      },
      {
        path: 'hotel',
        name: 'ApplicationHotel',
        component: () => import('@/views/application/components/hotel.vue')
      },
      {
        path: 'underground',
        name: 'ApplicationUnderground',
        component: () => import('@/views/application/components/underground.vue')
      }
    ]
  },
  {
    path: '/contact',
    name: 'Contact',
    redirect: '/about/contact',
  },
  {
    path: '/resource',
    name: 'Resource',
    redirect: '/resource/lightHealth',
    component: () => import('../views/resource/index.vue'),
    children: [
      {
        path: 'lightHealth',
        name: 'ResourceLightHealth',
        component: () => import('../views/resource/light-health/index.vue')
      }
    ]
  },
  {
    path: '/resource/download',
    name: 'Download',
    component: () => import('../views/download/index.vue')
  },
  {
    path: '/product',
    name: 'Product',
    redirect: '/product/skylightLamp',
    component: () => import('../views/product/index.vue'),
    children: [
      {
        path: 'skylightLamp',
        name: 'ProductSkylightLamp',
        component: () => import('../views/product/components/skylight-lamp/index.vue')
      },
      {
        path: 'ewindowSix',
        name: 'ProductEwindowSix',
        component: () => import('../views/product/components/ewindow-six/index.vue')
      },
      {
        path: 'coreTechnology',
        name: 'ProductCoreTechnology',
        component: () => import('../views/product/components/core-technology/index.vue')
      }
    ]
  },
  {
    path: '/product/details/:id',
    name: 'ProductDetails',
    component: () => import('../views/product/details/index.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404/index.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // const noRefush = ['application']
    if (to.matched[0].name !== from.matched[0].name) {
      return savedPosition ? savedPosition : { x: 0, y: 0 }
    }
  }
})

export default router
